import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Layout from "../components/layout";
// Icons
import { IoDiamond, IoLogoEuro, IoHammer } from 'react-icons/io5';
import Seo from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";


const About = () => {

    return (
        <Layout>
                <Seo
      title="About Us - BIKESAFE"
      description="We are a 'one stop shop' for those looking to purchase bike parking products. We offer a full service on our range of products from practical Sheffield bike stands to Cycle shelters, home bike parking and the Bike Dock"
    />
            <Container>
                <Row className="mt-5 mb-5 text-center">
                    <Col sm="12">
                        <h1>About Us</h1>
                        <h3>We are a "one stop shop" for those looking to purchase bike parking products.</h3>
                        <StaticImage placeholder="blurred"  src="../images/about-header.jpg" className="mt-4 mb-4 shadow fluid rounded" alt="Bikesafe Fabrication" />
                    </Col>
                </Row>
                <Row>
                    <Col lg="6" sm="12">
                        <h2 className="float-lg-left subheading-product">Our Objectives:</h2>
                    </Col>
                    <Col lg="6" sm="12">
                        <p className="flow-text">
                        We design many and offer a full installation service on all of the products that we sell.
                        </p>
                        <ul>
                            <li className="border p-2 shade-on-hover">We offer a full service on our range of products from practical Sheffield bike stands to Cycle shelters, home bike parking and the Bike Dock</li>
                            <li className="border p-2 shade-on-hover">We are an official supplier to the TFL and supply our products to everyone from businesses, education, contractors, local authority to home users.</li>
                            <li className="border p-2 shade-on-hover">We provide quality bike racks, including installation for the home, office and public areas.</li>
                            <li className="border p-2 shade-on-hover">We aim to manufacture products that are built with old-fashioned traditions of quality, innovative design with customer service in mind.</li>
                        </ul>
                    </Col>
                </Row>
                <Row className="mt-5 mb-5 text-center">
                    <Col sm="12">
                        <h3>see below how our bike stands are made, here in our factory:</h3>
                    </Col>
                </Row>
                <Row className="text-center">
                    <Col lg="4" sm="12">
                        <StaticImage placeholder="blurred"  src= "../images/factory/1.jpg" alt="Bikesafe Fabrication" className="mt-3 fluid rounded" />
                    </Col>
                    <Col lg="4" sm="12">
                        <StaticImage placeholder="blurred"  src= "../images/factory/2.jpg" alt="Bikesafe Fabrication" className="mt-3 fluid rounded" />
                    </Col>
                    <Col lg="4" sm="12">
                        <StaticImage placeholder="blurred"  src="../images/factory/3.jpg" alt="Bikesafe Fabrication" className="mt-3 fluid rounded" />
                    </Col>
                    <Col id="offsetColumnOne" className="d-none d-lg-block" lg="2" />
                    <Col lg="4" sm="12">
                        <StaticImage placeholder="blurred"  src="../images/factory/4.jpg" alt="Bikesafe Fabrication" className="mt-3 fluid rounded" />
                    </Col>
                    <Col lg="4" sm="12">
                        <StaticImage placeholder="blurred"  src="../images/factory/5.jpg" alt="Bikesafe Fabrication" className="mt-3 fluid rounded" />
                    </Col>
                    <Col id="offsetColumnTwo" className="d-none d-lg-block" lg="2" />
                </Row>
                <Row className="mt-5 mb-5 text-center">
                    <Col sm="12">
                        <h3>
                        All of our products have a few characteristics in common - that we feel is vital when purchasing any street furniture product:
                        </h3>
                        <p className="flow-text mt-5"><IoDiamond/> High Quality</p>
                        <p className="flow-text mt-5"><IoHammer/> Zero or minimal maintenance</p>
                        <p className="flow-text mt-5"><IoLogoEuro/> Great Value for money</p>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )

}

export default About;